import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AvatarGroup,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Container,
  Skeleton,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { calculateDueIn, statusComparator, statusFilterData } from "../helpers";
import {
  getProjectList,
  setProjectFilterStatus,
  getProjectStats,
} from "../../../redux/projects/projects";
import Avatars from "../../../components/uiElements/avatar/index";
import DataGridComponent from "../DataGridComponent";
import StatusComponent from "../StatusComponent";
import moment from "moment";
import {
  openProjectDetails,
  setSelectedProjectId,
} from "../../../redux/projects/project";
import { useLocation } from "react-router-dom";

var columnWidth = 0;
const projectStatusArray = [
  {
    title: "Total Projects",
    bgColor: "#4D4D4D",
    key: "total_count",
    value: "active",
  },
  {
    title: "Queued Projects",
    bgColor: "#89BDFA",
    key: "queued_count",
    value: "queued",
  },
  {
    title: "Pending Projects",
    bgColor: "#3B877B",
    key: "pending_count",
    value: "pending",
  },
  {
    title: "Ready to Complete ",
    bgColor: "#91C58A",
    key: "ready_to_complete_count",
    value: "ready_to_complete",
  },
  {
    title: "Overdue Projects",
    bgColor: "#DF564D",
    key: "overdue_tasks",
    value: "overdue",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto!important",
    height: "calc(100vh - 350px)",
  },
  detailCardSection: {
    justifyContent: "space-between !important",
    display: "flex!important",
    width: "100%!important",
    marginBottom: "40px!important",
    gap: "15px !important",
  },
  detailCard: {
    display: "flex!important",
    justifyContent: "unset!important",
    alignItems: "center!important",
    padding: "0px 19px!important",
    gap: "10px !important",
    height: "100px!important",
    width: "220px!important",
  },
  title: {
    fontSize: "32px!important",
    fontWeight: "400 !important",
    color: "white !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    lineHeight: "130%!important",
    color: "white !important",
  },
  avatar: {
    background: "#BDBDBD!important",
    color: "#fff!important",
    marginRight: "10px!important",
  },
  openButton: {
    width: "100%!important",
    border: "1px solid #0B0909!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
}));

const xgriColumns = [
  {
    field: "project",
    headerName: "Project",
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <div>
          <span
            style={{
              fontWeight: "700",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
              letterSpacing: "0.5px",
            }}
          >
            {params.value.name}
          </span>
          <span
            style={{
              fontWeight: "400",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
            }}
          >
            {params.value.address}
          </span>
        </div>
      );
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
  {
    field: "agent",
    headerName: "Agent",
    sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
    renderCell: (params) => {
      return (
        <ListItem
          alignItems="flex-start"
          sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
        >
          <ListItemAvatar>
            <Avatars
              sx={{
                marginRight: "10px",
                borderRadius: "4px",
                fontSize: "15px",
              }}
              src={params.value.image}
              variant="square"
            >{`${params.value?.first_name?.slice(0, 1) || "N"}${
              params.value?.last_name?.slice(0, 1) || "A"
            }`}</Avatars>
          </ListItemAvatar>
          <ListItemText
            primary={params.value.first_name}
            secondary={
              <React.Fragment>{params.value.last_name}</React.Fragment>
            }
          />
        </ListItem>
      );
    },
    width: columnWidth * 20 + 150,
    sortable: true,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    sortComparator: statusComparator,
    renderCell: (params) => {
      return <StatusComponent height="28px" status={params.value} />;
    },
    flex: 1,
    minWidth: 175,
    sortable: true,
    filterable: true,
  },
  {
    field: "progress",
    headerName: "Progress",
    sortComparator: (v1, v2) =>
      Math.round(parseInt(v1.percentage)) > Math.round(parseInt(v2.percentage)),
    type: "number",
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              sx={{ color: "#000" }}
              variant="determinate"
              value={params.value.percentage}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(parseInt(params.value.percentage))}%`}
              </Typography>
            </Box>
          </Box>
          <span style={{ paddingLeft: "5px" }}>{params.value.text}</span>
        </Box>
      );
    },
    flex: 1,
    minWidth: 200,
    sortable: true,
    filterable: false,
  },
  {
    field: "dueIn",
    headerName: "Due In",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    minWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    field: "teamMembers",
    headerName: "Team Members",
    renderCell: (params) => {
      return (
        <AvatarGroup
          sx={{
            "& .MuiAvatarGroup-root": {
              margin: "0px !important",
            },
            "& .MuiAvatarGroup-avatar": {
              margin: "0px !important",
              marginLeft: "-8px !important",
            },
            "& .MuiAvatar-circular": {
              margin: "-3px -12px!important",
            },
          }}
          max={3}
        >
          {params.value.map((image) => (
            <Avatars
              src={image.src}
              title={image.title}
              nameInitial={image.title}
              sx={{ marginLeft: "-5px" }}
            >{`${params.value?.title?.slice(0, 1) || "N"}${
              params.value?.title?.slice(0, 1) || "A"
            }`}</Avatars>
          ))}
        </AvatarGroup>
      );
    },
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "created",
    headerName: "Created At",
    renderCell: (params) => {
      return <>{moment(params.value || new Date()).format("MM/DD/YY H:MMa")}</>;
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
];

function ProjectGrid(props) {
  /**
   * Variables and States
   */
  const {
    value: selectedTab,
    departmentsActiveView,
    setDepartmentsActiveView,
    departmentID,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [status, setStatus] = useState("active");
  const [rows, setRows] = useState([]);
  const [columns] = useState([...xgriColumns]);
  const [dataGridRowHeight] = useState(50);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 20,
    search: "",
  });
  const [due_date_filter, setDue_date_filter] = useState("");
  const departments = useSelector((state) => state.project.departments);
  const projects = useSelector((state) => state.project.projects);
  const totalProjects = useSelector((state) => state.project.totalProjects);
  const projectsLoading = useSelector((state) => state.project.projectsLoading);
  const searchText = useSelector((state) => state.project.searchText);
  const projectStats = useSelector((state) => state.project.projectStats);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenProject = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(openProjectDetails(true));
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */

  useEffect(() => {
    if (!projectStats?.isLoading) {
      dispatch(getProjectStats({}));
    }
  }, []);

  useEffect(() => {
    if (selectedTab) {
      setFilterModel({
        items: [
          {
            columnField: "status",
            operatorValue: "contains",
            value:
              selectedTab === departments.length + 3
                ? "completed"
                : selectedTab === departments.length + 4
                ? "canceled"
                : "",
          },
        ],
      });

      dispatch(
        setProjectFilterStatus(
          selectedTab === departments.length + 3
            ? "completed"
            : selectedTab === departments.length + 4
            ? "canceled"
            : ""
        )
      );
    }
  }, [selectedTab]);

  useEffect(() => {
    const p = [];
    projects?.map((d, i) => {
      let dueIn = calculateDueIn(d.due_date);
      let teamMembers = [],
        alreadyExist = [];

      d.project_tasks.map((task) => {
        if (task.team_member && !alreadyExist.includes(task.team_member.id)) {
          teamMembers.push({
            src:
              task.team_member?.profile_images?.profile_img_thumbnail || null,
            title: task.team_member.full_name,
          });
          alreadyExist.push(task.team_member.id);
        }
      });

      // saving agent value length to calculate agent column width
      columnWidth =
        d?.agent?.full_name?.length > columnWidth
          ? d?.agent?.full_name?.length
          : columnWidth;
      let totalTasks = d.project_tasks.length;
      let totalTasksCompl = d.project_tasks.filter(
        (tsk) =>
          (tsk.approval_required &&
            (tsk.status === "approved" || tsk.status === "completed")) ||
          (!tsk.approval_required && tsk.status === "completed")
      ).length;
      let project = {
        id: d.id,
        status: d.status,
        project: {
          name: d?.project_name,
          address: d?.title,
        },
        agent: {
          image: d?.agent?.profile_images?.profile_img_thumbnail,
          last_name: d?.agent?.last_name,
          first_name: d?.agent?.first_name,
          full_name: d?.agent?.full_name,
        },
        progress: {
          percentage:
            totalTasksCompl !== 0
              ? parseInt((totalTasksCompl / totalTasks) * 100)
              : 0,
          text: `${totalTasksCompl} of ${totalTasks} Tasks Complete`,
        },
        dueIn,
        teamMembers,
        department: d.department,
        created: d.createdAt,
      };

      p.push(project);
    });
    setRows(p);
  }, [projects]);

  useEffect(() => {
    let newStatus = [];
    let FilterItem = statusFilterData?.find((item) => item.value === status);
    if (FilterItem?.status) {
      newStatus = FilterItem.status;
    } else {
      newStatus = FilterItem?.value ? [`${FilterItem.value}`] : status;
    }
    dispatch(
      getProjectList({
        ...filters,
        searchText: searchText,
        status: newStatus,
        departmentID,
        due_date_filter,
      })
    );
  }, [filters, filterModel, searchText, status, due_date_filter]);

  useEffect(() => {
    if (state?.project_id) {
      handleOpenProject(state?.project_id);
    }
  }, [state?.project_id]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <>
      <Container className={classes.detailCardSection} maxWidth="lg">
        {projectStats?.isLoading
          ? projectStatusArray?.map((project, index) => (
              <Card className={classes.detailCard}>
                <Skeleton style={{ height: "100px", width: "100%" }} />
              </Card>
            ))
          : projectStatusArray?.map((project, index) => (
              <Card
                className={classes.detailCard}
                sx={{ background: project.bgColor, cursor: "pointer" }}
                onClick={() => {
                  if (project.value === "overdue") {
                    setDue_date_filter("overdue");
                  } else {
                    setDue_date_filter("");
                    setStatus(project.value);
                  }
                }}
              >
                <div>
                  <Typography className={classes.title}>
                    {projectStats?.data?.[project.key] || 0}
                  </Typography>
                  <Typography className={classes.desc}>
                    {project.title}
                  </Typography>
                </div>
              </Card>
            ))}
      </Container>

      <Container className={classes.container} maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "10px",
            gap: "15px",
          }}
        >
          {props.departmentID ? (
            <TextField
              select
              classes={{ root: classes.detailInput }}
              value={departmentsActiveView}
              onChange={(e) => setDepartmentsActiveView(e.target.value)}
              variant="outlined"
              sx={{ width: 180, background: "white" }}
              size="small"
            >
              <MenuItem value={"projects"}>Projects</MenuItem>
              <MenuItem value={"tasks"}>Tasks</MenuItem>
            </TextField>
          ) : null}
          <TextField
            select
            classes={{ root: classes.detailInput }}
            label="Status"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setDue_date_filter("");
            }}
            variant="outlined"
            sx={{ width: 200, background: "white" }}
            size="small"
          >
            {statusFilterData.map((item) => (
              <MenuItem key={item} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <DataGridComponent
          rows={rows}
          rowHeight={dataGridRowHeight}
          columns={columns}
          loading={projectsLoading}
          rowCount={totalProjects}
          hideFooter
          onRowsScrollEnd={(e) => {
            if (totalProjects > e.virtualRowsCount && !projectsLoading) {
              let newStatus = [];
              let FilterItem = statusFilterData?.find(
                (item) => item.value === status
              );
              if (FilterItem.status) {
                newStatus = FilterItem.status;
              } else {
                newStatus = [`${FilterItem.value}`];
              }

              dispatch(
                getProjectList({
                  ...filters,
                  pageNumber: Number(e.virtualRowsCount / 20) + 1,
                  pageSize: 20,
                  status: newStatus,
                  departmentID,
                  due_date_filter,
                })
              );
            }
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(fm) => {
            if (fm.items.length > 0) {
              dispatch(setProjectFilterStatus(fm.items[0].value || ""));
              setFilterModel(fm);
            } else {
              dispatch(setProjectFilterStatus(""));
              setFilterModel({ items: [] });
            }
          }}
          onCellClick={(row) => handleOpenProject(row.id)}
        />
      </Container>
    </>
  );
}

export default ProjectGrid;
